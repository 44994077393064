import { Route, Redirect, useHistory } from 'react-router-dom';
import { ALLOWED_ROUTES_AFTER_LOGIN, ROUTES } from '../../../constants/routes';
import { useSelector } from 'react-redux';

export const GuestRoute = ({ children, ...rest }) => {
  const history = useHistory();
  let isLogin = localStorage.getItem('login');
  const userPermissions = useSelector((state) => state.login.userPermissions);


  const redirectRoute = (routesList, permissionsList) => {
    const permissions = permissionsList.map(el => el.name);
    const accessRoutes = routesList.filter(el => permissions.includes(el.permission));

    if (permissionsList.length !== 0) {
      if (accessRoutes.length !== 0) {
        return accessRoutes[0].route;
      } else {
        return ROUTES.userSettings;
      }
    }
  };

  return (
    <Route
      {...rest}
      render={() => {
        const url = new URLSearchParams(history.location.search.slice(1));

        return isLogin === 'true' && userPermissions !== undefined ? (
          <Redirect
            to={url.get('redirect') || redirectRoute(ALLOWED_ROUTES_AFTER_LOGIN, userPermissions?.length !== 0 ? userPermissions : [{ name: 'userWithoutPermission' }])} />
        ) : (
          children
        );
      }}
    />
  );
};
