import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { userPasswordConfirmationValidation } from '../../utils/validationSchema';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import AppLogo from '../../assets/logos/logo-now-access.png';
import { DescriptionStyled } from '../../components/StyledComponents/StyledComponents';
import { LabelStyled } from '../../components/LabelStyled/LabelStyled';
import { GroupStyled } from '../../components/GroupStyled/GroupStyled';
import { MaskInput } from '../../components/MaskInput/MaskInput';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { ContainerStyled } from '../../components/ContainerStyled/ContainerStyled';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { hideLoader, showLoader } from '../../utils/common';
import { ApiUserManagement } from '../../api/ApiUserManagement';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/actions/login';

export const SetNewPasswordPage = () => {
  const dispatch = useDispatch();
  const apiUserManagement = useMemo(() => new ApiUserManagement(), []);

  const userData = useSelector((state) => state.login.userData);

  return (
    <BackgroundStyled justify='center'>
      <ContainerStyled height={'480px'}>
        <Formik
          validationSchema={userPasswordConfirmationValidation}
          initialValues={{
            newPassword: '',
            passwordConfirmation: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={false}
          onSubmit={(values, { resetForm }) => {
            const newPassword = {
              password: values.newPassword,
              password_confirmation: values.passwordConfirmation,
            };
            (async () => {
              showLoader();
              const [setUpNewPassword] = await Promise.all([
                await apiUserManagement.changeUserPassword(newPassword),
              ]);
              if (setUpNewPassword) {
                dispatch(setUserData({
                  ...userData,
                  password_changed_at: setUpNewPassword?.data?.data?.password_changed_at,
                }));
                resetForm();
              } else {
                hideLoader();
              }
            })();
          }}
        >
          {({ handleSubmit, handleChange }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FlexStyled>
                <ImgStyled bottom={'0'} src={AppLogo} alt='logo' />
              </FlexStyled>
              <DescriptionStyled>
                <span>- Password must be at least 8 characters</span>
                <span>- You can't use your first name, last name, email, organization, and phone as a part of a password</span>
                <span>- Your password will be checked with the most common password library</span>
              </DescriptionStyled>
              <LabelStyled fontSize={'14px'}>Please set up a new password</LabelStyled>
              <GroupStyled bottom={'33px'}>
                <LabelStyled>New Password</LabelStyled>
                <Field
                  id={'newPassword'}
                  component={MaskInput}
                  width={'100%'}
                  name='newPassword'
                  placeholder='New Password'
                  onChange={handleChange}
                />
              </GroupStyled>
              <GroupStyled bottom={'33px'}>
                <LabelStyled>Password Confirmation</LabelStyled>
                <Field
                  id={'passwordConfirmation'}
                  component={MaskInput}
                  width={'100%'}
                  name='passwordConfirmation'
                  placeholder='Password Confirmation'
                  onChange={handleChange}
                />
              </GroupStyled>
              <FlexStyled direction={'row'}>
                <ButtonPrimary type='submit' buttonName='Update password' />
              </FlexStyled>
            </Form>
          )}
        </Formik>
      </ContainerStyled>
    </BackgroundStyled>
  );
};