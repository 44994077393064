export const LOGIN_MESSAGES = {
  email: 'Enter your email',
  validEmail: 'Enter a valid email',
  requiredEmail: 'Email is required',
  password: 'Enter your password',
  minLengthPassword: 'Should be of minimum 8 characters length',
  requiredPassword: 'Password is required',
  terms: 'Term must be accepted',
  maxLength: 8,
};

export const USER_MESSAGES = {
  name: 'Enter your name',
  requiredName: 'Name is required',
  email: 'Enter your email',
  validEmail: 'Enter a valid email',
  requiredEmail: 'Email is required',
  password: 'Enter your password',
  minLengthPassword: 'Should be of minimum 12 characters length',
  requiredPassword: 'Password is required',
  passwordConfirmation: 'Enter your password confirmation',
  requiredPasswordConfirmation: 'Password confirmation is required',
  firstName: 'Enter your first name',
  requiredFirstName: 'First name is required',
  lastName: 'Enter your last name',
  requiredLastName: 'Last name is required',
  organization: 'Enter your organizations',
  requiredOrganization: 'Organizations is required',
  department: 'Enter your department',
  requiredDepartment: 'Department is required',
  phone: 'Enter your phone',
  requiredPhone: 'Phone is required',
  phoneRegExp: 'Phone number is not valid',
  phoneMinLength: 'Min phone number length 5 characters',
  phoneMaxLength: 'Max phone number length 15 characters',
  requiredRole: 'Field role is required',
  maxLength: 8,
  passwordsCompare: 'Passwords must match',
  alphabetsField: 'Only alphabets are allowed for this field',
  requiredStatus: 'Field status is required',
};

export const ROLE_MESSAGES = {
  requiredRoleTitle: 'Role Title is required',
};

export const QR_CODE_MESSAGES = {
  requiredCode: 'Code is required',
};

export const PERMISSION_MESSAGES = {
  name: 'Name is required',
  action: 'Action is required',
  app_id: 'Application is required',
  description: 'Description app is required',
};
export const ORGANIZATION_MESSAGES = {
  name: 'Name is required',
};

export const SUCCESSFUL_TOAST_MESSAGES = {
  success: true,
  text: 'Successfully completed',
  isShown: 'visible',
  ssss: 'lol',
};


