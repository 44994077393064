import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../components/LabelStyled/LabelStyled';
import { InputValidation } from '../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { Api } from '../../api/Api';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled,
  DescriptionStyled, MenuNavigationStyled, WrapperStyled, BtnWrapperStyled,
} from '../../components/StyledComponents/StyledComponents';
import { CardStyledLeftBlock, CardStyledRightBlock, CardStyledContainer } from './StyledComponents';
import { PlusCircleStyled } from '../../utils/iconsStyled';
import { ToggleSwitch } from '../../components/ToggleSwitch/ToggleSwitch';
import { RadioButtonList } from '../../components/RadioButtonList/RadioButtonList';
import { TwoFactor } from '../../api/TwoFactor';
import QRCode from 'qrcode';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import { setUserData } from '../../redux/actions/login';
import { passwordUpdateValidation } from '../../utils/validationSchema';
import { MaskInput } from '../../components/MaskInput/MaskInput';
import { ScrollStyled } from '../../components/ScrollStyled/ScrollStyled';
import { UploadWrapper } from '../../components/LogoUpload/UploadWrapper';
import { setUserDataToLocalStorage } from '../../utils/auth';

const SIDE_MENU = [
  {
    label: 'Personal Information',
    activeClass: 'active',
    to: 'personalInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Display Information',
    activeClass: 'active',
    to: 'displayInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Email Notification Settings',
    activeClass: 'active',
    to: 'emailNotificationSettings',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Table Settings',
    activeClass: 'active',
    to: 'tableSettings',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Change Password',
    activeClass: 'active',
    to: 'changePassword',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  // {
  //   label: 'Security App And Privacy',
  //   activeClass: 'active',
  //   to: 'securityAppAndPrivacy',
  //   spy: true,
  //   smooth: false,
  //   offset: -100,
  //   duration: 500,
  // },
];


export const UserSettings = () => {
  const dispatch = useDispatch();
  const twoFactor = useMemo(() => new TwoFactor(), []);
  const api = useMemo(() => new Api(), []);

  const [qrCode, setQrCode] = useState('');

  const userData = useSelector((state) => state.login.userData);

  useEffect(() => {
    if (userData?.user_settings?.is_two_factor_auth_enabled) {
      const qr = twoFactor.getUrl();

      qr.then((response) => {
        if (response) {
          QRCode.toDataURL(response.data.data).then(setQrCode);
        }
      });
    }
  }, [api, twoFactor, userData?.user_settings?.is_two_factor_auth_enabled]);

  const themesData = [
    { label: 'DARK_THEME', id: 'DARK_THEME', value: 'theme-dark' },
    // { label: 'LIGHT_THEME', id: 'LIGHT_THEME', value: 'theme-light' },
    // { label: 'BLUE_THEME', id: 'BLUE_THEME', value: 'theme-blue' },
  ];

  const handleChangeTheme = (e) => {
    const updatedUserData = { ...userData };
    updatedUserData.user_settings.theme = e.target.value;
    setUserDataToLocalStorage(updatedUserData);
    dispatch(setUserData(updatedUserData));
  };

  const handleChangeEmailNotification = () => {
    const updatedUserData = { ...userData };
    userData.user_settings.new_device_login = !userData.user_settings.new_device_login;
    setUserDataToLocalStorage(updatedUserData);
    dispatch(setUserData(updatedUserData));
  };

  const handleUserLogo = async (imageBlob) => {
    await api.updateUserProfileLogo(imageBlob);
    await api.userInfo();
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      <Container>
        <FlexStyled
          direction={'row'}
          align={'flex-start'}
          justify={'center'}
          wrap={'wrap'}
        >
          <MenuNavigationStyled>
            <PageTitleStyled padding={'20px 20px 0'}>User Profile</PageTitleStyled>
            <ul>
              {SIDE_MENU.map(item =>
                <li key={item.label}>
                  <ScrollStyled
                    activeClass={item.activeClass}
                    to={item.to}
                    spy={item.spy}
                    smooth={item.smooth}
                    offset={item.offset}
                    duration={item.duration}
                  >{item.label}
                  </ScrollStyled>
                </li>)}
            </ul>
          </MenuNavigationStyled>
          <WrapperStyled width={'580px'}>
            <Formik
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                firstName: userData?.first_name ? userData?.first_name : '',
                lastName: userData?.last_name ? userData?.last_name : '',
                phone: userData?.phone_number ? userData?.phone_number : '',
                itemsPerPage: userData?.user_settings?.items_per_page ? userData?.user_settings?.items_per_page : '',
                theme: userData?.user_settings?.theme ? userData?.user_settings?.theme : '',
                newDeviceLogin: userData?.user_settings?.new_device_login ? userData?.user_settings?.new_device_login : false,
              }}
              onSubmit={async (values) => {
                const userSettings = {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  phone_number: values.phone,
                  items_per_page: values.itemsPerPage,
                  theme: values.theme,
                  new_device_login: values.newDeviceLogin,
                };
                await api.updateUserProfile(userSettings);
                await api.userInfo();
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <FlexStyled
                    direction={'column'}
                    align={'stretch'}
                    justify={'center'}
                    wrap={'wrap'}
                  >
                    <CardStyled width={'580px'} marginBottom={'15px'}>
                      <CardTitleStyled id={'personalInformation'}>PERSONAL
                        INFORMATION</CardTitleStyled><CardStyledContainer>
                      <CardStyledLeftBlock>
                        <UploadWrapper originalImageSrc={userData?.image_url} onHandleImage={handleUserLogo}>
                          <PlusCircleStyled size={'120px'} />
                        </UploadWrapper>
                      </CardStyledLeftBlock>
                      <CardStyledRightBlock>
                        <GroupStyled top={'13px'} bottom={'13px'}>
                          <LabelStyled bottom={'8px'}>First Name</LabelStyled>
                          <Field
                            id={'firstName'}
                            component={InputValidation}
                            type='text'
                            width={'100%'}
                            name={'firstName'}
                            placeholder={'First Name'}
                            onChange={handleChange}
                          />
                        </GroupStyled>
                        <GroupStyled top={'13px'} bottom={'13px'}>
                          <LabelStyled bottom={'8px'}>Last Name</LabelStyled>
                          <Field
                            id={'lastName'}
                            component={InputValidation}
                            type='text'
                            width={'100%'}
                            name={'lastName'}
                            placeholder={'Last Name'}
                            onChange={handleChange}
                          />
                        </GroupStyled>
                        <GroupStyled top={'13px'} bottom={'13px'}>
                          <LabelStyled bottom={'8px'}>Phone number</LabelStyled>
                          <Field
                            id={'phone'}
                            component={InputValidation}
                            type='number'
                            width={'100%'}
                            name={'phone'}
                            placeholder={'Phone number'}
                            onChange={handleChange}
                          />
                        </GroupStyled>
                      </CardStyledRightBlock>
                    </CardStyledContainer>
                    </CardStyled>
                    <CardStyled marginBottom={'15px'}>
                      <CardTitleStyled id={'displayInformation'}>DISPLAY SETTINGS</CardTitleStyled>
                      <CardStyledContainer justifyContent={'flex-start'} flexDirection={'column'}>
                        <LabelStyled top={'12px'} bottom={'12px'}>Select Theme</LabelStyled>
                        <RadioButtonList
                          name={'theme'}
                          radioButtonSettings={themesData}
                          checkedValue={userData?.user_settings?.theme}
                          onChangeValue={handleChangeTheme} />
                      </CardStyledContainer>
                    </CardStyled>
                    <CardStyled marginBottom={'15px'}>
                      <CardTitleStyled id={'emailNotificationSettings'}>EMAIL NOTIFICATION SETTINGS</CardTitleStyled>
                      <CardStyledContainer>
                        <ToggleSwitch
                          id='emailNotification'
                          checked={userData?.user_settings?.new_device_login ? userData?.user_settings?.new_device_login : false}
                          onChangeStatus={handleChangeEmailNotification}
                          label={'Notify me of login attempts from a new device'}
                          padding={'25px 0 20px'}
                        />
                      </CardStyledContainer>
                    </CardStyled>
                    <CardStyled marginBottom={'15px'}>
                      <CardTitleStyled id={'tableSettings'}>TABLE SETTINGS</CardTitleStyled>
                      <CardStyledContainer justifyContent={'flex-start'}>
                        <GroupStyled top={'13px'} bottom={'13px'}>
                          <LabelStyled bottom={'8px'}>Set items per page</LabelStyled>
                          <Field
                            id={'itemsPerPage'}
                            component={InputValidation}
                            type='number'
                            width={'256px'}
                            name={'itemsPerPage'}
                            placeholder={'Items per page'}
                            onChange={handleChange}
                          />
                        </GroupStyled>
                      </CardStyledContainer>
                    </CardStyled>
                    <BtnWrapperStyled>
                      <FlexStyled direction={'row'} top={'10px'} bottom={'30px'} justify={'center'}>
                        <ButtonPrimary type='submit' buttonName='Update Profile' />
                      </FlexStyled>
                    </BtnWrapperStyled>
                  </FlexStyled>
                </Form>
              )}
            </Formik>
            <FlexStyled
              direction={'column'}
              align={'stretch'}
              justify={'center'}
              wrap={'wrap'}
            >
              <CardStyled width={'580px'} marginBottom={'15px'}>
                <CardTitleStyled id={'changePassword'}>CHANGE PASSWORD</CardTitleStyled>
                <Formik
                  validationSchema={passwordUpdateValidation}
                  enableReinitialize={true}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    newPassword: '',
                    passwordConfirmation: '',
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const newPassword = {
                      password: values.newPassword,
                      password_confirmation: values.passwordConfirmation,
                    };
                    await api.updateUserPassword(newPassword, resetForm);
                  }}
                >
                  {({ handleSubmit, handleChange, setFieldValue, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FlexStyled
                        direction={'column'}
                        align={'stretch'}
                        justify={'start'}
                        wrap={'wrap'}
                      >
                        <CardStyled marginBottom={'15px'}>
                          <CardStyledContainer flexDirection={'column'}>
                            <DescriptionStyled>
                              <span>- Password must be at least 8 characters</span>
                              <span>- You can't use your first name, last name, email, organization, and phone as a part of a password</span>
                              <span>- Your password will be checked with the most common password library</span>
                            </DescriptionStyled>
                            <GroupStyled top={'13px'} bottom={'13px'} width={'256px'}>
                              <LabelStyled bottom={'8px'}>New Password</LabelStyled>
                              <Field
                                id={'newPassword'}
                                component={MaskInput}
                                type='text'
                                width={'100%'}
                                name={'newPassword'}
                                placeholder={'New Password'}
                                onChange={handleChange}
                              />
                            </GroupStyled>
                            <GroupStyled top={'13px'} bottom={'13px'} width={'256px'}>
                              <LabelStyled bottom={'8px'}>Password confirmation</LabelStyled>
                              <Field
                                id={'passwordConfirmation'}
                                component={MaskInput}
                                type='text'
                                width={'100%'}
                                name={'passwordConfirmation'}
                                placeholder={'Password confirmation'}
                                onChange={handleChange}
                              />
                            </GroupStyled>
                          </CardStyledContainer>
                          <FlexStyled direction={'row'} justify={'center'}>
                            <ButtonPrimary type='submit' buttonName='Update password' />
                          </FlexStyled>
                        </CardStyled>
                      </FlexStyled>
                    </Form>
                  )}
                </Formik>
              </CardStyled>
              {userData?.user_settings?.is_two_factor_auth_enabled && (
                <CardStyled marginBottom={'15px'}>
                  <CardTitleStyled id={'securityAppAndPrivacy'}>SECURITY APP AND PRIVACY</CardTitleStyled>
                  <CardStyledContainer flexDirection={'column'} alignItems={'center'}>
                    <LabelStyled bottom={'8px'} top={'8px'} alignSelf={'start'}>2FA Configuration</LabelStyled>
                    <ImgStyled width={'170px'} height={'170px'} src={qrCode} alt='qrCode' />
                  </CardStyledContainer>
                </CardStyled>
              )}
            </FlexStyled>
          </WrapperStyled>
        </FlexStyled>
      </Container>
    </BackgroundStyled>
  );
};
