import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { qrCodeValidation } from '../../utils/validationSchema';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import { InputValidation } from '../../components/InputValidation/InputValidation';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { TwoFactor } from '../../api/TwoFactor';
import QRCode from 'qrcode';
import { hideLoader, showLoader } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/actions/login';
import {
  FormBlockStyled,
  FormDescriptionStyled,
  FormHeaderStyled, FormInputWrapperStyled, FormListItemStyled, FormStyled,
  FormTitleTextStyled, FormWrapperStyled,
} from '../../components/FormStyled/FormStyled';


export const TwoFactorConfigurePage = () => {
  const dispatch = useDispatch();
  const twoFactor = useMemo(() => new TwoFactor(), []);
  const [qrCode, setQrCode] = useState('');
  const userData = useSelector((state) => state.login.userData);

  useEffect(() => {
    let unmounted = false;
    (async () => {
      showLoader();

      const [twoFactorQrCode] = await Promise.all([
        twoFactor.getUrl(),
      ]);

      if (!unmounted && twoFactorQrCode) {
        QRCode.toDataURL(twoFactorQrCode.data?.data).then(setQrCode);
      }
      hideLoader();
    })();

    return () => {
      unmounted = true;
    };
  }, [twoFactor]);

  return (
    <BackgroundStyled justify='center'>
      <FormStyled>
        <FormHeaderStyled>
          <FlexStyled align={'start'}>
            <FormTitleTextStyled>Two-factor authentication</FormTitleTextStyled>
          </FlexStyled>
        </FormHeaderStyled>
        <Formik
          validationSchema={qrCodeValidation}
          initialValues={{
            code: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { resetForm }) => {
            const verifyCode = values.code;
            (async () => {
              showLoader();
              const [twoFactorVerifyCode] = await Promise.all([
                await twoFactor.enable(verifyCode),
              ]);

              if (twoFactorVerifyCode) {
                const userDataCopy = { ...userData };
                userDataCopy.user_settings.is_two_factor_auth_enabled = true;
                dispatch(setUserData({
                  userDataCopy,
                }));
                resetForm();
              }
            })();
          }}
        >
          {({ handleSubmit, handleChange }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormWrapperStyled>
                <FlexStyled align={'flex-start'}>
                  {qrCode !== '' ? (
                    <ImgStyled width={'170px'} height={'170px'} src={qrCode} alt='qrCode' />
                  ) : (<FormBlockStyled />)}
                </FlexStyled>

                <FlexStyled left={'10px'}>
                  <FormDescriptionStyled>For security reasons, it is mandatory to have 2FA for all users.
                    Please follow these steps to access your account:</FormDescriptionStyled>
                  <ol>
                    <FormListItemStyled>Open Authenticator mobile application (e.g. Microsoft Authenticator, Google
                      Authenticator, Duo
                      Mobile) and scan your QR code.
                    </FormListItemStyled>
                    <FormListItemStyled>Enter the one-time password generated by the application and click
                      confirm.</FormListItemStyled>
                  </ol>
                  <FormInputWrapperStyled>
                    <Field
                      id={'code'}
                      component={InputValidation}
                      type='text'
                      width={'170px'}
                      name={'code'}
                      placeholder={'Enter the 6-digit code'}
                      onChange={handleChange}
                    />
                  </FormInputWrapperStyled>
                </FlexStyled>
              </FormWrapperStyled>
              <FlexStyled bottom={'15px'} direction={'row'}>
                <ButtonPrimary type='submit' buttonName='Confirm' />
              </FlexStyled>
            </Form>
          )}
        </Formik>
      </FormStyled>
    </BackgroundStyled>
  );
};