import { showErrorToast } from '../utils/common';
import { axiosInstance, API_URL_OLD } from './ApiConfig';

const API_PATHS = {
  PASSWORD: API_URL_OLD + '/users/profile/password',
};

export class ApiUserManagement {

  async handleApiCall(requestConfig = {}) {
    try {
      return await axiosInstance(requestConfig);

    } catch (error) {
      showErrorToast(error.message);

      if (process.env.NODE_ENV !== 'production') {
        console.log('UserManagement Error: ' + error.message);
      }
    }
  }

  async changeUserPassword(userPassword) {
    return this.handleApiCall({
      method: 'PATCH',
      url: API_PATHS.PASSWORD,
      data: userPassword,
    });
  }
}