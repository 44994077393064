import { showToastAction } from '../redux/actions/toast';
import store from '../redux/store';
import { setIsLoading } from '../redux/actions/spinner';

export const showToastWithTimeout = (data) => {
  const timeout = data.timeout || 3000;
  const toastData = Object.assign({}, data);

  store.dispatch(showToastAction(toastData));
  toastData.isShown = 'hidden';

  setTimeout(() => {
    store.dispatch(showToastAction(toastData));
  }, timeout);
};

/**
 * @param {string} message
 * @param {boolean} success
 * @param {number} timeout
 */
export const showToast = (message, success, timeout) => {
  let data = { success: success, isShown: 'visible', text: message }

  store.dispatch(showToastAction(data))

  data = { ...data, ...{ isShown: 'hidden' }}

  setTimeout(() => {
    store.dispatch(showToastAction(data))
  }, timeout)
}

export const showErrorToast = (message, timeout = 3000) => {
  showToast(message, false, timeout)
}

export const showSuccessToast = (message, timeout = 3000) => {
  showToast(message, true, timeout)
}

export const concatName = (firstName, lastName) => {
  const name = firstName + lastName;
  return name.replace(/ /g, '_');
};

/**
 * @param {array} userPermissions
 * @param {object} permissionGranted
 * @return {boolean}
 *
 */
export const hasPermission = (userPermissions, permissionGranted) => {
  if (userPermissions) {
    return userPermissions.some((permission) => permission.name === permissionGranted.name);
  }
};

export const showLoader = () => {
  // const waitBeforeShow = 500;
  // setTimeout(() => store.dispatch(setIsLoading(true)), waitBeforeShow);
  store.dispatch(setIsLoading(true));
};

export const hideLoader = () => {
  store.dispatch(setIsLoading(false));
};

export const loadData = async (callback, args) => {
  const waitAfterHide = 500;

  showLoader();
  const start = performance.now();
  await callback(...args);
  const duration = performance.now() - start;

  if (duration < waitAfterHide) {
    hideLoader();
  } else {
    setTimeout(() => {
      hideLoader();
    }, waitAfterHide);
  }
};

