import styled from 'styled-components';
import { SIZE } from '../../constants/size';

export const FormStyled = styled.div`
  margin: ${(props) => props.margin || '10px'};
  width: ${(props) => props.width || '580px'};
  text-align: ${(props) => props.textAlign || 'center'};
  background: ${({ theme }) => theme.formBackgroundColor};
  border-radius: ${(props) => props.radius || '0px'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
`;

export const FormHeaderStyled = styled.div`
  width: 100%;
  padding: ${(props) => props.padding || '19px 19px'};
  border-bottom: ${({ theme }) => `2px solid ${theme.formHeaderBorderBottomColor}`};
`;

export const FormTitleTextStyled = styled.span`
  color: ${({ theme }) => theme.formCardTitleColor};
  text-transform: uppercase;
  font-size: 12px;
  margin: 0;
`;

export const FormWrapperStyled = styled.span`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${SIZE.tabletS}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FormListItemStyled = styled.li`
  color: ${({ theme }) => theme.formListItemColor};
  text-align: left;
  padding: 5px 15px 5px 5px;
`;

export const FormDescriptionStyled = styled.h1`
  padding: 10px 15px 10px 5px;
  color: ${({ theme }) => theme.formDescriptionColor};
  font-size: 12px;
  text-align: left;
  margin: ${(props) => props.margin || '0px'};
`;

export const FormInputWrapperStyled = styled.div`
  margin: ${(props) => props.margin || '18px 0'};
  padding: ${(props) => props.padding || '0px'};
  width: ${(props) => props.width || '100%'};
  @media (max-width: ${SIZE.tabletS}) {
    width: ${(props) => props.mediaWidth || 'auto%'};
  }
`;

export const FormBlockStyled = styled.div`
  width: 190px;
  height: 190px;
`;


