import styled from 'styled-components';

export const ContainerStyled = styled.div`
  max-width: 580px;
`;

export const CardStyledLeftBlock = styled.div`
  width: 254px;
  text-align: center;
  margin: auto;
`;

export const CardStyledRightBlock = styled.div`
  width: 254px;
`;

export const Wrapper = styled.div`
  padding: 0 20px 13px
`;

export const CardStyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  padding-top: ${(props) => props.paddingTop || '0px'};
  padding-bottom: ${(props) => props.paddingBottom || '20px'};
  padding-left: ${(props) => props.paddingLeft || '20px'};
  padding-right: ${(props) => props.paddingRight || '20px'};
  align-items: ${(props) => props.alignItems || 'space-around'};
`;


