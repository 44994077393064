import styled from 'styled-components';
import { SIZE } from '../../constants/size';

export const CardStyled = styled.div`
  min-width: ${(props) => props.minWidth || '200px'};
  background-color: ${({ theme }) => theme.cardStyledBackgroundColor};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  max-width: ${(props) => props.width || '580px'};
`;

export const WrapperStyled = styled.div`
  margin: 0 10px;
  width: ${(props) => props.width || '580px'};
  flex-grow: 3;

  @media (max-width: ${SIZE.tabletS}) {
    flex-grow: 0;
  }
`;

export const MenuNavigationStyled = styled.div`
  flex-grow: 1;
  position: sticky;
  left: 0;
  right: 0;
  top: 110px;
  background-color: ${({ theme }) => theme.menuNavigationBackgroundColor};

  ul {
    list-style-type: none;
    margin: 10px;
    padding: 0;
  }

  ul li {
    padding: 10px;
  }

  ul li a {
    text-decoration: underline;
    color: ${({ theme }) => theme.menuNavigationTextColor};

    &:hover {
      transition: 0.2s ease-in-out;
      color: ${({ theme }) => theme.menuNavigationTextHoverColor};
    }
  }

  @media (max-width: ${SIZE.tabletS}) {
    display: none;
  }
`;

export const Container = styled.div`
  max-width: 1192px;
  margin: 0 auto;
  padding-top: 109px;
  padding-bottom: 50px;
`;

export const PageTitleStyled = styled.h1`
  color: ${({ theme }) => theme.pageTitleStyledColor};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  padding: ${(props) => props.padding || '20px'};
`;

export const CardTitleStyled = styled.h2`
  color: ${({ theme }) => theme.cardTitleStyledColor};
  font-size: 12px;
  padding: ${(props) => props.padding || '24px 24px'};
  border-bottom: ${({ theme }) => `2px solid ${theme.cardTitleStyledBorderBottomColor}`};
`;

export const FlexStyled = styled.div`
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  display: flex;
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  flex-direction: ${(props) => props.direction || 'column'};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
  padding-top: ${(props) => props.paddingTop || '0px'};
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  padding-left: ${(props) => props.paddingLeft || '0px'};
  padding-right: ${(props) => props.paddingRight || '0px'};
`;

export const TabsStyled = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const CheckBoxListWrapper = styled.div`
  overflow-y: scroll;
  height: 238px;
`;

export const BtnWrapperStyled = styled.div`
  max-width: 580px;
`;


export const DescriptionStyled = styled.p`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ theme }) => theme.descriptionTextColor};
  text-align: ${(props) => props.align || 'left'};
  padding-top: 15px;
  width: 300px;
`;

