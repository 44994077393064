import React, { useCallback, useEffect, useMemo } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { THEMES } from './themes/index';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SpinnerStyled } from './components/SpinnerStyled/SpinnerStyled';
import { ToastStyled } from './components/ToastStyled/ToastStyled';
import { useDispatch } from 'react-redux';
import { applyTheme } from './redux/actions/themes';
import { Api } from './api/Api';
import { Routes } from './routes/Routes/Routes';
import { setUserData, setUserPermissions } from './redux/actions/login';
// import * as _ from 'lodash';
import {
  getUserDataFromLocalStorage,
  getUserPermissionsFromLocalStorage, setLoggedIn,
  setUserPermissionsToLocalStorage,
} from './utils/auth';
import { ALLOWED_ROUTES_AFTER_LOGIN, ROUTES } from './constants/routes';
import { hideLoader, showErrorToast, showLoader } from './utils/common';


const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-family: 'proxima-nova', sans-serif;
    outline: none !important;
    box-shadow: none !important;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(74, 74, 82, .3);
      background-color: #323238;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      -webkit-box-shadow: inset 0 0 6px rgba(74, 74, 82, .3);
      background-color: #323238;
    }
  }
`;

const getTheme = (themeName) => {
  switch (themeName) {
    case THEMES.lightTheme:
      return THEMES.lightTheme;
    case THEMES.blueTheme:
      return THEMES.blueTheme;
    default:
      return THEMES.darkTheme;
  }
};

const App = () => {
  const api = useMemo(() => new Api(), []);
  const history = useHistory();
  const dispatch = useDispatch();
  const toastData = useSelector((state) => state.toast);
  const isLoading = useSelector((state) => state.spinner.isLoading);
  const theme = useSelector((state) => state.theme.theme);
  const userData = useSelector((state) => state.login.userData);
  const userPermissions = useSelector((state) => state.login.userPermissions);
  const entityAndRole = useSelector((state) => state.login.entityAndRole);

  const checkUser = useCallback(async () => {
    let unmounted = false;

    const chosenEntityAndRole = await api.getChosenEntityAndRole();

    if (chosenEntityAndRole?.status === 200) {
      const roleId = chosenEntityAndRole.data.role_id;
      const permissionsRole = await api.getRole(roleId);

      // const apps = await api.getApplications();
      // let isSetPermission = false;
      // _.forEach(apps['data'], (app) => {
      //   if (app['name'] === 'Copacabana') {
      //     _.forEach(permissionsRole['data']['permissions'], (permission) => {
      //       if (permission['app_id'] === app['id']) {
      //         isSetPermission = true;
      //         return false;
      //       }
      //     });
      //   }
      //
      //   if (isSetPermission) {
      //     return false;
      //   }
      // });

      if (!unmounted && permissionsRole?.status === 200) {
        setUserPermissionsToLocalStorage(permissionsRole.data.permissions);
        dispatch(setUserPermissions(getUserPermissionsFromLocalStorage()));
        setLoggedIn();
        history.push(ALLOWED_ROUTES_AFTER_LOGIN, userPermissions?.length !== 0 ? userPermissions : [{ name: 'userWithoutPermission' }]);
      } else {
        await api.logout();
        showErrorToast('An error has occurred');
      }
    } else {
      history.push(ROUTES.selectOrganization);
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line
  }, [api, dispatch, history]);

  const checkLoginParams = useCallback(async (is_organization_admin, is_tester, is_admin) => {
    let unmounted = false;

    if (is_organization_admin === 1 && is_tester === 0 && is_admin === 0) {
      await checkUser();
    } else if (is_organization_admin === 0 && is_tester === 1 && is_admin === 0) {
      await checkUser();
    } else if (is_organization_admin === 0 && is_tester === 0 && is_admin === 0) {
      await checkUser();
    } else if (is_organization_admin === 0 && is_tester === 0 && is_admin === 1) {
      const permissions = await api.getPermissionsActive();
      if (!unmounted && permissions?.status === 200) {
        setLoggedIn();
        setUserPermissionsToLocalStorage(permissions.data);
        dispatch(setUserPermissions(getUserPermissionsFromLocalStorage()));
        history.push(ALLOWED_ROUTES_AFTER_LOGIN, userPermissions?.length !== 0 ? userPermissions : [{ name: 'userWithoutPermission' }]);
      }
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line
  }, [api, checkUser, dispatch, history]);

  useEffect(() => {
    dispatch(setUserData(getUserDataFromLocalStorage()));

    if (getUserPermissionsFromLocalStorage() === null) {
      dispatch(setUserPermissions([]));
    } else {
      dispatch(setUserPermissions(getUserPermissionsFromLocalStorage()));
    }

    (async () => {
      showLoader();
      const checkAuth = await api.userInfo();

      const isOrganizationAdmin = userData.is_organization_admin;
      const isTester = userData.is_tester;
      const isAdmin = userData.is_admin;

      if (checkAuth?.status === 200) {
        if (checkAuth?.data?.password_changed_at !== null) {
          if (checkAuth?.data?.user_settings?.is_two_factor_auth_enabled === false) {
            await checkLoginParams(isOrganizationAdmin, isTester, isAdmin);
          }
          if (checkAuth?.data?.user_settings?.is_two_factor_auth_enabled === true) {
            if (checkAuth?.data?.user_settings?.two_factor_auth_configured_at !== null && checkAuth?.data?.sso_session?.two_fa_authenticated === true) {
              await checkLoginParams(isOrganizationAdmin, isTester, isAdmin);
            }
            if (checkAuth?.data?.user_settings?.two_factor_auth_configured_at === null && checkAuth?.data?.sso_session?.two_fa_authenticated === false) {
              history.push(ROUTES.twoFactor);
            }
            if (checkAuth?.data?.user_settings?.two_factor_auth_configured_at !== null && checkAuth?.data?.sso_session?.two_fa_authenticated === false) {
              history.push(ROUTES.twoFactorAuth);
            }
          }
        }

        if (checkAuth?.data?.password_changed_at === null) {
          history.push(ROUTES.setNewPassword);
        }
      }
      hideLoader();
    })();

    dispatch(applyTheme(THEMES.darkTheme));

  }, [dispatch, api, checkLoginParams, userData?.password_changed_at, userData?.user_settings?.is_two_factor_auth_enabled, userData?.user_settings?.two_factor_auth_configured_at, userData?.sso_session?.two_fa_authenticated, userData?.is_organization_admin, userData?.is_tester, userData?.is_admin, entityAndRole, history]);

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Global />
      <ToastStyled
        showToast={toastData.isShown}
        text={toastData.text}
        success={toastData.success}
      />
      {isLoading && <SpinnerStyled />}
      <Routes />
    </ThemeProvider>
  );
};

export default App;
