import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute';
import { GuestRoute } from '../components/GuestRoute/GuestRoute';
import { LoginPage } from '../../pages/LoginPage/LoginPage';
import { UserManagement } from '../../pages/UserManagement/UserManagement';
import { AddUser } from '../../pages/UserManagement/pages/AddUser/AddUser';
import { EditUser } from '../../pages/UserManagement/pages/EditUser/EditUser';
import { Organizations } from '../../pages/Organizations/Organizations';
import { AddOrganization } from '../../pages/Organizations/pages/AddOrganization/AddOrganization';
import { Logs } from '../../pages/Logs/Logs';
import { Permissions } from '../../pages/Permissions/Permissions';
import { AddPermission } from '../../pages/Permissions/pages/AddPermission/AddPermission';
import { EditPermission } from '../../pages/Permissions/pages/EditPermission/EditPermission';
import { RoleManagement } from '../../pages/RoleManagement/RoleManagement';
import { EditRole } from '../../pages/RoleManagement/pages/EditRole/EditRole';
import { AddRole } from '../../pages/RoleManagement/pages/AddRole/AddRole';
import { ResetPassword } from '../../pages/ResetPassword/ResetPassword';
import { AccessDenied } from '../../pages/AccessDenied/AccessDenied';
import { UserSettings } from '../../pages/UserSettings/UserSettings';
import { ForgotPassword } from '../../pages/ForgotPassword/ForgotPassword';
import { PermissionRoute } from '../components/PermissionRoute/PermissionRoute';
// import { EditOrganization } from '../../pages/Organizations/pages/EditOrganization/EditOrganization';
import { DuplicateRole } from '../../pages/RoleManagement/pages/DuplicateRole/DuplicateRole';
import { PERMISSIONS } from '../../constants/permission';
import { ROUTES } from '../../constants/routes';
import { TwoFactorAuthPage } from '../../pages/TwoFactorAuthPage/TwoFactorAuthPage';
import { TwoFactorConfigurePage } from '../../pages/TwoFactorConfigurePage/TwoFactorConfigurePage';
import { SetNewPasswordPage } from '../../pages/SetNewPasswordPage/SetNewPasswordPage';
import { ActiveSessionRoute } from '../components/ActiveSessionRoute/ActiveSessionRoute';
import { SelectOrganizationPage } from '../../pages/SelectOrganizationPage/SelectOrganizationPage';


export const Routes = () => {

  return (
    <Switch>
      <GuestRoute path={ROUTES.login}>
        <LoginPage />
      </GuestRoute>
      <Route exact path={ROUTES.resetPassword} component={ResetPassword} />
      <Route exact path={ROUTES.forgotPassword} component={ForgotPassword} />
      <ActiveSessionRoute exact path={ROUTES.twoFactor} component={TwoFactorConfigurePage} />
      <ActiveSessionRoute exact path={ROUTES.twoFactorAuth} component={TwoFactorAuthPage} />
      <ActiveSessionRoute exact path={ROUTES.setNewPassword} component={SetNewPasswordPage} />
      <ActiveSessionRoute exact path={ROUTES.selectOrganization} component={SelectOrganizationPage} />
      <PrivateRoute exact path={ROUTES.userSettings} component={UserSettings} />
      <PrivateRoute exact path={ROUTES.logs} component={Logs} />
      <PrivateRoute exact path={ROUTES.accessDenied} component={AccessDenied} />
      <PermissionRoute
        exact path={ROUTES.organizations}
        component={Organizations}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_organization }}
      />
      <PermissionRoute
        exact path={ROUTES.addOrganization}
        component={AddOrganization}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_organization }}
      />
      {/*<PermissionRoute*/}
      {/*  exact path={ROUTES.editOrganization}*/}
      {/*  component={EditOrganization}*/}
      {/*  permissionGranted={{ name: PERMISSIONS.copa_access_to_organization }}*/}
      {/*/>*/}
      <PermissionRoute
        exact path={ROUTES.userManagement}
        component={UserManagement}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_users }}
      />
      <PermissionRoute
        exact path={ROUTES.addUser}
        component={AddUser}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_users }}
      />
      <PermissionRoute
        exact path={ROUTES.editUser}
        component={EditUser}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_users }}
      />
      <PermissionRoute
        exact path={ROUTES.roleManagement}
        component={RoleManagement}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}
      />
      <PermissionRoute
        exact path={ROUTES.editRole}
        component={EditRole}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}
      />
      <PermissionRoute
        exact path={ROUTES.addRole}
        component={AddRole}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}
      />
      <PermissionRoute
        exact path={ROUTES.duplicateRole}
        component={DuplicateRole}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}
      />
      <PermissionRoute
        exact path={ROUTES.permissions}
        component={Permissions}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_permissions }}
      />
      <PermissionRoute
        exact path={ROUTES.editPermission}
        component={EditPermission}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_permissions }}
      />
      <PermissionRoute
        exact path={ROUTES.addPermission}
        component={AddPermission}
        permissionGranted={{ name: PERMISSIONS.copa_access_to_permissions }}
      />
      <Redirect to={ROUTES.login} />
    </Switch>
  );
};
