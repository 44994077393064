export const PERMISSIONS = {
  //Pages
  copa_access_to_organization: 'copa_access_to_organization',
  copa_access_to_users: 'copa_access_to_users',
  copa_access_to_roles: 'copa_access_to_roles',
  copa_access_to_permissions: 'copa_access_to_permissions',
  //Apps
  copa_access_to_metroon: 'copa_access_to_metroon',
  copa_access_to_ibiza: 'copa_access_to_ibiza',
  copa_access_to_peloponnese: 'copa_access_to_peloponnese',
  copa_access_to_nowCP: 'copa_access_to_nowCP',
  copa_access_to_API: 'copa_access_to_API',
  copa_access_to_markets: 'copa_access_to_markets',
};
