import { showErrorToast } from '../utils/common'
import { BASE_API_URL, axiosInstance, API_PATHS } from './ApiConfig';

export class ApiApplications {

  async getApplications() {
    let url = `${BASE_API_URL}${API_PATHS.applicationPaths.applications}`;

    try {
      return await axiosInstance.get(url);
    } catch (error) {
      showErrorToast(error.response?.message || 'Apps api error has occurred')

      if (process.env.NODE_ENV !== 'production'){
        console.log('Apps api Error: ' + error.message)
      }
    }
  }
}