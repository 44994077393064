import React, { useEffect, useMemo, useState } from 'react';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import AppLogo from '../../assets/logos/logo-now-access.png';
import { DescriptionStyled } from '../../components/StyledComponents/StyledComponents';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { ContainerStyled } from '../../components/ContainerStyled/ContainerStyled';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { hideLoader, showLoader } from '../../utils/common';
import { useDispatch } from 'react-redux';
import { setEntityAndRole } from '../../redux/actions/login';
import { RadioButtonList } from '../../components/RadioButtonList/RadioButtonList';
import { Api } from '../../api/Api';
import { ListStyled } from './StyledComponents';
import { ButtonDisabled } from '../../components/ButtonDisabled/ButtonDisabled';

export const SelectOrganizationPage = () => {
  const api = useMemo(() => new Api(), []);
  const dispatch = useDispatch();

  const [organizationsList, setOrganizationsList] = useState([]);
  const [organizationRolesList, setOrganizationRolesList] = useState([]);
  const [userRoleAndOrganization, setUserRoleAndOrganization] = useState(
    {
      'entity_id': null,
      'role_id': null,
    },
  );
  useEffect(() => {
    let unmounted = false;
    (async () => {
      showLoader();
      const [listOfAllowedEntitiesAndRoles] = await Promise.all([
        await api.getListOfAllowedEntitiesAndRoles(),
      ]);

      if (!unmounted && listOfAllowedEntitiesAndRoles?.data?.length !== 0) {
        setOrganizationsList(listOfAllowedEntitiesAndRoles?.data);
      }
      hideLoader();
    })();

    return () => {
      unmounted = true;
    };
  }, [api]);

  useEffect(() => {
    if (userRoleAndOrganization.entity_id !== null) {
      setOrganizationRolesList(formattedRadioButtonsData(organizationsList.filter(el => el.id === userRoleAndOrganization.entity_id)[0].roles));
    }
  }, [userRoleAndOrganization, organizationsList]);

  const formattedRadioButtonsData = (data) => {
    if (data) {
      const list = [];
      data.forEach(e => list.push({ label: e.name, id: e.id, value: e.id }));
      return list;
    }
  };

  const handleChangeRole = (e) => {
    const roleAndOrganizationSettings = { ...userRoleAndOrganization };
    roleAndOrganizationSettings.role_id = +e.target.value;
    setUserRoleAndOrganization(roleAndOrganizationSettings);
  };

  const handleChangeUserOrganization = (e) => {
    const organizationId = +e.target.value;
    const roleAndOrganizationSettings = { ...userRoleAndOrganization };
    roleAndOrganizationSettings.entity_id = organizationId;
    roleAndOrganizationSettings.role_id = null;
    setUserRoleAndOrganization(roleAndOrganizationSettings);
  };

  const handleSelectRoleAndPermission = async () => {
    const roleAndEntity = await api.setRoleAndEntity(userRoleAndOrganization);
    if (roleAndEntity?.status === 200) {
      dispatch(setEntityAndRole(true));
    }
  };

  return (
    <BackgroundStyled justify='center'>
      <ContainerStyled height={'none'} justify={'start'}>
        <FlexStyled>
          <ImgStyled top={'40px'} src={AppLogo} alt='logo' />
        </FlexStyled>
        <div>
          <DescriptionStyled>Organizations: </DescriptionStyled>

          <ListStyled>
            <RadioButtonList
              name={'Organizations'}
              radioButtonSettings={formattedRadioButtonsData(organizationsList)}
              checkedValue={`${userRoleAndOrganization.entity_id}`}
              onChangeValue={handleChangeUserOrganization}
            />
          </ListStyled>
          <DescriptionStyled>Roles:</DescriptionStyled>
          <ListStyled>
            <RadioButtonList
              name={'Roles'}
              radioButtonSettings={organizationRolesList}
              checkedValue={`${userRoleAndOrganization.role_id}`}
              onChangeValue={handleChangeRole}
            />
          </ListStyled>
        </div>
        {userRoleAndOrganization.role_id !== null ? (
          <ButtonPrimary
            bottom={'15px'}
            buttonName={'Select'}
            onClick={async () => {
              await handleSelectRoleAndPermission();
            }}
          />) : (
          <ButtonDisabled
            bottom={'15px'}
            buttonName={'Select'}
          />
        )}
      </ContainerStyled>
    </BackgroundStyled>
  );
};